import React from 'react'
import PropTypes from 'prop-types'

import styles from '../../containers/Navigation/Navigation.scss'

const NavLink = ({ id, aria, children, text, href, icon, onClick, onFocus, onKeyDown, onMouseEnter, onMouseLeave }) => (
  <li className={styles.item} data-title={id}>
    <a
      id={id}
      href={href}
      aria-labelledby={aria.labelledBy}
      aria-haspopup={aria.hasPopup}
      aria-expanded={aria.expanded}
      className={styles.link}
      data-el={id}
      onClick={onClick}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-test={id.toLowerCase()}>
      <span className={styles.linkText}>
        <span className={styles.linkContent}>
          {text}
          {icon}
        </span>
      </span>
    </a>
    {children}
  </li>
)

NavLink.propTypes = {
  aria: PropTypes.shape({
    labelledBy: PropTypes.string,
    hasPopup: PropTypes.bool,
    expanded: PropTypes.bool,
  }),
  href: PropTypes.string,
  icon: PropTypes.element,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  text: PropTypes.string,
}

NavLink.defaultProps = {
  aria: {
    labelledBy: null,
    hasPopup: null,
    expanded: null,
  },
  text: null,
  href: '#',
  icon: null,
  onClick: () => {},
  onFocus: () => {},
  onKeyDown: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
}

export default NavLink
