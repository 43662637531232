import React from 'react'
import PropTypes from 'prop-types'

import { megaMenuUrlBuilder } from '../../helpers/analyticsUrlBuilder'

import styles from './MegaMenuLink.scss'

const MegaMenuLink = ({
  aria,
  children,
  role,
  tabIndex,
  text,
  href,
  dataTest,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => (
  <li className={styles.menuItem} role={role}>
    {text !== 'IMAGE_HEADER' ? (
      <a
        aria-labelledby={aria.labelledBy}
        aria-haspopup={aria.hasPopup}
        aria-expanded={aria.expanded}
        href={`${href}${megaMenuUrlBuilder(text)}`}
        tabIndex={tabIndex}
        className={styles.link}
        data-megamenu-link
        data-test={dataTest}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}>
        {text}
      </a>
    ) : null}
    {children}
  </li>
)

MegaMenuLink.propTypes = {
  aria: PropTypes.shape({
    labelledBy: PropTypes.string,
    hasPopup: PropTypes.bool,
    expanded: PropTypes.bool,
  }),
  dataTest: PropTypes.string,
  href: PropTypes.string.isRequired,
  isHeading: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  role: PropTypes.string,
  tabIndex: PropTypes.number,
  text: PropTypes.string.isRequired,
}

MegaMenuLink.defaultProps = {
  aria: {
    labelledBy: null,
    hasPopup: null,
    expanded: null,
  },
  isHeading: false,
  role: null,
  tabIndex: 0,
  dataTest: null,
  onClick: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
}

export default MegaMenuLink
