import request from 'superagent'

import endpoints from '../config/endpoints'

export default () =>
  new Promise((resolve, reject) =>
    request
      .get(endpoints.user)
      .then((result) => {
        if (!result.body) return reject(new Error(`Invalid response: ${result.body}`))
        return resolve(result.body)
      })
      .catch((error) => reject(new Error(error))),
  )

export const getTrolleyCount = () =>
  new Promise((resolve) =>
    request
      .get(endpoints.trolleyCount)
      .ok((res) => res.status < 500)
      .then((result) => {
        if (!result.body && result.body !== 0) {
          return resolve(0)
        }
        return resolve(result.body)
      })
      .catch(() => {
        return resolve(0)
      }),
  )

// Create CIS session cookie if it doesn't exist
export const setSession = () => {
  return new Promise((resolve, reject) => {
    request
      .post(endpoints.session)
      .set('callOrigin', 'argos')
      .send()
      .then((result) => resolve(result.body))
      .catch((error) => reject(new Error(error)))
  })
}

// Refresh call with syncLocalisation header
// Temporarily used by AO test to rollback
export const setSyncLocalisation = () => {
  return new Promise((resolve, reject) => {
    request
      .post(endpoints.session)
      .set('syncLocalisation', 'true')
      .send()
      .then((result) => resolve(result.body))
      .catch((error) => reject(new Error(error)))
  })
}

export const signOut = ({ storeId, langId, catalogId }) =>
  new Promise((resolve, reject) =>
    request
      .get(`${endpoints.signOut}?storeId=${storeId}&langId=${langId}&catalogId=${catalogId}`)
      .then((result) => {
        if (result.statusCode !== 200) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return reject({ signedOut: false })
        }

        return resolve({ signedOut: true })
      })
      // eslint-disable-next-line prefer-promise-reject-errors
      .catch((error) => reject({ signedOut: false, error })),
  )
