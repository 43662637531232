export default {
  wishlist: '/wishlist-service/gql-wishlist',
  user: '/webapp/wcs/stores/servlet/GetUserInfo?langId=110&storeId=10151',
  meganav: '/meganav.json',
  trolleyCount: '/basket-api/v1/basket/items/count',
  suggestions: '/suggest',
  session: '/cis/refresh',
  signOut: '/webapp/wcs/stores/servlet/ArgosLogoff',
  megaButtons: 'https://www.argos.co.uk/assets/globalnav/megaButtons.json',
}
