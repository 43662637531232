import React, { Element } from 'react'
import PropTypes from 'prop-types'

import { Logo, SkipLink } from '../../components'

import headerStyles from '../../containers/Header/Header.scss'

export const MiniHeader = ({ sticky, skipLinks, miniHeaderContent, headerRef, routes, dualBrand, triBrand }) => (
  <header
    id='haas-v2-mini'
    className={`${headerStyles.header}${sticky ? ` ${headerStyles['header--sticky']}` : ''}`}
    ref={headerRef}>
    <div className={headerStyles.main}>
      <div className={headerStyles.container}>
        {skipLinks && skipLinks.length > 0
          ? skipLinks.map((skipLink) => (
              <SkipLink key={skipLink.id} id={skipLink.id} name={skipLink.name} skipTo={skipLink.skipTo} isMiniHeader />
            ))
          : null}
        <Logo routes={routes} dualBrand={dualBrand} triBrand={triBrand} />
        {miniHeaderContent}
      </div>
    </div>
  </header>
)

MiniHeader.propTypes = {
  dualBrand: PropTypes.bool,
  headerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  miniHeaderContent: PropTypes.element,
  routes: PropTypes.shape({ homePage: PropTypes.func }),
  skipLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      skipTo: PropTypes.string.isRequired,
    }),
  ),
  sticky: PropTypes.bool,
  triBrand: PropTypes.bool,
}

MiniHeader.defaultProps = {
  sticky: false,
  dualBrand: false,
  triBrand: false,
  skipLinks: [],
  miniHeaderContent: null,
  routes: {},
}

export default MiniHeader
