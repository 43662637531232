import request from 'superagent'

import endpoints from '../config/endpoints'

export default (origin = '') =>
  new Promise((resolve) =>
    request
      .get(`${origin}${endpoints.meganav}`)
      .then((result) => {
        if (!result.body) {
          console.error(`Invalid response: ${result.body}`)
          return resolve([])
        }

        const meganav = result.body.body.data

        return resolve(meganav)
      })
      .catch((error) => {
        console.error(error)
        return resolve([])
      }),
  )
