/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { ChevronIcon } from '@boltui/icons'

import styles from '../MegaMenuCategories/MegaMenuCategories.scss'
import { isDesktop } from '../../helpers/viewPortHelper'

const MegaMenuCategory = ({
  category,
  hasChevron,
  hasHoverExclusionZone,
  href,
  idx,
  isExpanded,
  isFlyout,
  isSeasonal,
  menuLevel,
  onClick,
  onKeyDown,
  onMouseEnter,
  onMouseLeave,
  onSetRef,
  onTouchStart,
}) => {
  const ariaLabel = (subcategory) =>
    `To hear more ${
      subcategory.title
    } links, please hit the spacebar key to enter the menu. Or click here to go to all of ${subcategory.title}.`
  return (
    <li className={`${styles.category} ${styles.menuItem} ${isSeasonal ? styles.seasonal : ''}`}>
      <a
        aria-expanded={isExpanded}
        aria-label={isDesktop(global) && ariaLabel(category)}
        className={`${styles.link} ${isSeasonal ? styles.seasonal : ''}`}
        data-megamenu-link
        data-test={`megamenu-category-${idx}`}
        href={href}
        onClick={(evt) => onClick(idx, category, menuLevel + 1, evt)}
        onKeyDown={(evt) => onKeyDown(idx, category, evt)}
        onMouseEnter={(evt) => onMouseEnter(idx, category, evt)}
        onMouseLeave={onMouseLeave}
        onTouchStart={(evt) => onTouchStart(idx, category, evt)}
        ref={(ref) => onSetRef(ref, idx, isFlyout)}
        tabIndex={0}>
        {category.title}
        {hasChevron && (
          <ChevronIcon dir='right' data-test={`megamenu-category-chevron-${idx}`} className={styles.rightArrow} />
        )}
      </a>

      {hasHoverExclusionZone && (
        <span
          aria-hidden={true}
          className={styles.hoverExclusionZone}
          data-test={`megamenu-category-hover-zone-${idx}`}
          onMouseEnter={(evt) => onMouseEnter(idx, category, evt)}
          onMouseLeave={onMouseLeave}
        />
      )}
    </li>
  )
}

MegaMenuCategory.propTypes = {
  category: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
    links: PropTypes.array,
    columns: PropTypes.array,
  }),
  hasChevron: PropTypes.bool,
  hasHoverExclusionZone: PropTypes.bool,
  href: PropTypes.string,
  idx: PropTypes.number,
  isExpanded: PropTypes.bool,
  isFlyout: PropTypes.bool,
  isSeasonal: PropTypes.bool,
  level: PropTypes.number,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onSetRef: PropTypes.func,
  onTouchStart: PropTypes.func,
}

MegaMenuCategory.defaultProps = {
  category: {
    title: null,
    link: null,
    links: null,
    columns: null,
  },
  hasChevron: false,
  hasHoverExclusionZone: false,
  href: null,
  idx: null,
  isExpanded: false,
  isFlyout: false,
  isSeasonal: false,
  level: null,
  onClick: /* istanbul ignore next */ () => {},
  onKeyDown: /* istanbul ignore next */ () => {},
  onMouseEnter: /* istanbul ignore next */ () => {},
  onMouseLeave: /* istanbul ignore next */ () => {},
  onSetRef: /* istanbul ignore next */ () => {},
  onTouchStart: /* istanbul ignore next */ () => {},
}

export default MegaMenuCategory
