import React from 'react'
import PropTypes from 'prop-types'

import styles from './MegaMenuImage.scss'

const MegaMenuImage = ({ href, src, alt }) => (
  <li>
    <a href={href} title={alt} target='_self' tabIndex={0} data-megamenu-image>
      <img className={styles.image} alt={alt} src={`https://www.argos.co.uk${src}`} />
    </a>
  </li>
)

MegaMenuImage.propTypes = {
  alt: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
}

export default MegaMenuImage
