export default {
  userState: {
    loggedIn: 'LOGGEDIN',
    recognised: 'RECOGNISED',
    guest: 'GUEST',
  },
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  autoComplete: {
    screenReaderDescription:
      'Use up and down arrows to review and enter to select. Touch device users, explore by touch or with swipe gestures.',
  },
}
