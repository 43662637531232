import React from 'react'

import styles from './SkipLink.scss'

const SkipLink = ({ id, name, skipTo, isMiniHeader }) => (
  <div className={styles.skip} id={id} data-test={'skip-button'}>
    <a className={`${styles.skipButton} ${isMiniHeader ? styles.miniHeader : ``}`} href={skipTo}>
      {name}
    </a>
  </div>
)

export default SkipLink
