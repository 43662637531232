import { createStore } from 'react-contextual'

import WishlistStore from './Wishlist'
import UserService, { signOut, getTrolleyCount, setSession, setSyncLocalisation } from '../services/User'

import endpoints from '../config/endpoints'
import config from '../config/config'

export const initialState = {
  emailAddress: '',
  firstName: '',
  postCode: '',
  trolleyCount: 0,
  userId: '',
  userState: config.userState.guest,
}
export default createStore({
  ...initialState,
  fetchUser: (callback) =>
    UserService().then((userResult) => {
      WishlistStore.state.fetchWishlistCount(userResult.userState === config.userState.loggedIn)
      return getTrolleyCount().then((count) => {
        const userObject = { ...userResult, trolleyCount: count }
        callback(userObject)
        return userObject
      })
    }),
  setSession: () => setSession(),
  setSyncLocalisation: () => setSyncLocalisation(),
  signOut: (queryParams, goToHomepage = false) =>
    signOut(queryParams).then((result) => {
      if (result.signedOut) {
        if (goToHomepage) {
          window.location.href = '/'
        } else {
          window.location.reload()
        }
        return initialState
      }
      window.location.href = `${endpoints.signOut}?storeId=${queryParams.storeId}&langId=${
        queryParams.langId
      }&catalogId=${queryParams.catalogId}`
      return {}
    }),
})
