import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import slideLeftTransition from '../../static/transitions/menuSlideLeft.scss'
import slideRightTransition from '../../static/transitions/menuSlideRight.scss'

class MegaMenuSlideTransition extends React.Component {
  constructor(props) {
    super(props)
    this.nodeRef = React.createRef()
  }

  render() {
    const { children, enter, exit, isSlidingLeft, show, unmountOnExit } = this.props
    return (
      <CSSTransition
        nodeRef={this.nodeRef}
        classNames={isSlidingLeft ? slideLeftTransition : slideRightTransition}
        in={show}
        enter={enter}
        exit={exit}
        timeout={500}
        unmountOnExit={unmountOnExit}>
        <div ref={this.nodeRef}>{children}</div>
      </CSSTransition>
    )
  }
}

MegaMenuSlideTransition.propTypes = {
  children: PropTypes.element,
  enter: PropTypes.bool,
  exit: PropTypes.bool,
  isSlidingLeft: PropTypes.bool,
  show: PropTypes.bool,
  unmountOnExit: PropTypes.bool,
}

MegaMenuSlideTransition.defaultProps = {
  enter: true,
  exit: true,
  isSlidingLeft: true,
  show: false,
  unmountOnExit: true,
}

export default MegaMenuSlideTransition
