import React from 'react'
import ContentLoader from 'react-content-loader'

export default (props) => (
  <ContentLoader height={215} width={300} speed={2} primaryColor='#f3f3f3' secondaryColor='#ecebeb' {...props}>
    <rect x='0' y='17' rx='2' ry='5' width={300} height='13' />
    <rect x='0' y='60' rx='2' ry='5' width={300} height='13' />
    <rect x='0' y='103' rx='2' ry='5' width={300} height='13' />
    <rect x='0' y='146' rx='2' ry='5' width={300} height='13' />
    <rect x='0' y='189' rx='2' ry='5' width={300} height='13' />
  </ContentLoader>
)
