import request from 'superagent'

import endpoints from '../config/endpoints'

export default () => {
  return new Promise((resolve) =>
    request
      .get(`${endpoints.megaButtons}`)
      .then((result) => {
        if (!result.body) {
          console.error(`Invalid response: ${result.body}`)
          return resolve([])
        }
        return resolve(result.body)
      })
      .catch((error) => {
        console.error(error)
        return resolve([])
      }),
  )
}
