import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { subscribe } from 'react-contextual'
import ErrorBoundary from '@argos/error-boundary'

import { NavigationStore } from '../../stores'
import styles from './UserLink.scss'

export class UserLink extends Component {
  static propTypes = {
    className: PropTypes.string,
    count: PropTypes.oneOfType([PropTypes.number, PropTypes.element]),
    href: PropTypes.string.isRequired,
    icon: PropTypes.func.isRequired,
    menuToggled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  }

  static defaultProps = {
    count: null,
    onClick: () => {},
  }

  get doubleDigitCount() {
    return !isNaN(this.props.count) && this.props.count > 9
  }

  render() {
    const { count, icon, name, onClick, className, href, menuToggled } = this.props

    const Icon = icon

    return (
      <a
        className={className}
        href={href}
        onClick={onClick}
        data-test={name.toLowerCase()}
        data-menu-active={menuToggled}>
        {Icon && <Icon className={styles.icon} />}
        {(React.isValidElement(count) && count.props.children > 0) || count > 0 ? (
          <span className={styles.dot} data-double-digit={this.doubleDigitCount}>
            {count}
          </span>
        ) : null}
        <span className={styles.iconText}>{name}</span>
      </a>
    )
  }
}

const SubscribedComponent = subscribe([NavigationStore], (navigation) => ({
  menuToggled: navigation.menuToggled,
}))(subscribe()(UserLink))

const ProvidedComponent = (props) => (
  <ErrorBoundary>
    <SubscribedComponent {...props} />
  </ErrorBoundary>
)

export default ProvidedComponent
