import React from 'react'
import PropTypes from 'prop-types'

import styles from '../../containers/Navigation/Navigation.scss'

const MobileSectionTitle = ({ text, id }) => (
  <li data-title={id} className={styles.item}>
    <span className={styles.mobileSectionTitle}>{text}</span>
  </li>
)

MobileSectionTitle.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
}

MobileSectionTitle.defaultProps = {
  id: 'NavMobileTitle',
}

export default MobileSectionTitle
