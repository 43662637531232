import React from 'react'
import PropTypes from 'prop-types'

import { ChevronIcon } from '@boltui/icons'
import device from '@argos/utils/esnext/device'
import canUseDom from '@argos/utils/esnext/canUseDom'

import { NavLink } from '../../components'
import { megaButtonsUrlBuilder } from '../../helpers/analyticsUrlBuilder'

import styles from './MegaButtons.scss'

const handleMegaButtonRoute = (evt, idx, routes, href, closeNav, onClick) => {
  if (!href) {
    onClick(evt)
  } else if (routes.megaButtons && routes.megaButtons[idx] && typeof routes.megaButtons[idx] === 'function') {
    evt.preventDefault()
    closeNav(evt)
    routes.megaButtons[idx].call(this, href)
  }
}

const generateLink = ({ link, idx, routes, className, closeNav, onClick, displayId }) => {
  const href = megaButtonsUrlBuilder(link.link, link.text)

  return (
    <NavLink
      key={`${link.text}${idx}`}
      className={className}
      href={href}
      id={`${displayId + idx}`}
      text={link.text}
      onClick={(evt) => handleMegaButtonRoute(evt, idx, routes, href, closeNav, onClick)}
    />
  )
}

const generateFlyout = ({
  link,
  idx,
  routes,
  className,
  closeNav,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onKeyDown,
  flyoutActive,
  displayId,
}) => {
  const href = null
  const icon = <ChevronIcon dir='right' className={styles.arrowIcon} />

  return (
    <NavLink
      aria={{
        hasPopup: true,
        expanded: flyoutActive,
      }}
      key={`${link.text}${idx}`}
      className={className}
      id={`${displayId + idx}`}
      text={link.text}
      icon={icon}
      onClick={(evt) => handleMegaButtonRoute(evt, idx, routes, href, closeNav, onClick)}
      onKeyDown={onKeyDown}
      onMouseEnter={(evt) => canUseDom() && !device.isIpad() && onMouseEnter(evt, true)}
      onMouseLeave={onMouseLeave}
    />
  )
}

const MegaButtons = ({
  links,
  routes,
  className,
  closeNav,
  flyoutActive,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onKeyDown,
  displayId,
}) =>
  links && links.length > 0
    ? links.map((link, idx) => {
        if (link.links) {
          return generateFlyout({
            link,
            idx,
            routes,
            className,
            closeNav,
            onClick,
            onMouseEnter,
            onMouseLeave,
            onKeyDown,
            flyoutActive,
            displayId,
          })
        }
        return generateLink({ link, idx, routes, className, closeNav, onClick, onMouseLeave, displayId })
      })
    : null

MegaButtons.defaultProps = {
  className: {},
  displayId: 'megabutton',
  links: [],
  onKeyDown: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  routes: {},
}

MegaButtons.propTypes = {
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  closeNav: PropTypes.func,
  displayId: PropTypes.string,
  flyoutActive: PropTypes.bool,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  onKeyDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  routes: PropTypes.shape({
    help: PropTypes.func,
    stores: PropTypes.func,
    'advice-and-inspiration': PropTypes.func,
    search: PropTypes.func,
    megaButtons: PropTypes.arrayOf(PropTypes.func),
  }),
}

export default MegaButtons
