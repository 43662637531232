import React from 'react'
import PropTypes from 'prop-types'
import { ChevronIcon } from '@boltui/icons'

import { megaMenuLevelOneUrlBuilder } from '../../helpers/analyticsUrlBuilder'

import styles from './MegaMenuShopAll.scss'

export const MegaMenuShopAll = ({ category, shopAllRef }) =>
  category.title && category.link ? (
    <li className={`mega-menu-column ${styles.shopAllColumn}`}>
      <a
        ref={shopAllRef}
        className={styles.shopAllLink}
        href={`${category.link}${megaMenuLevelOneUrlBuilder(category.link)}`}
        tabIndex='0'>
        Shop all {category.title}
        <ChevronIcon dir='right' className={styles.rightArrow} aria-hidden={true} />
      </a>
    </li>
  ) : null

MegaMenuShopAll.propTypes = {
  category: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
  }),
  shopAllRef: PropTypes.shape({
    current: PropTypes.string,
  }),
}

MegaMenuShopAll.defaultProps = {
  category: {
    title: '',
    link: '',
  },
  shopAllRef: {},
}

export default MegaMenuShopAll
