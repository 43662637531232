export const keyCodes = {
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  TAB: 9,
  ESC: 27,
  SPACE: 32,
  ENTER: 13,
}

/**
 * Handle keyboard controls & focus accessiblity on a generic, single level menu
 * @param {object} event keydown event.
 * @param {object} config Object containing necessary menu states and callbacks.
 * @property {number}  config.focusedItem - index of current focused L1 menu item
 * @property {number}  config.menuLength - total amount of items within the list
 * @property {string}  config.startElementTag - element tag to launch the menu on TAB
 * @property {string}  config.endElementTag - element tag to close the menu on SHIFT + TAB
 * @property {function}  config.onUpdateFocusedItem - callback to update state with newly focused item
 * @property {function}  config.onSetKeyboardActivated - callback to update state whether opened via keyboard
 * @property {function}  config.onDestroy - callback to close the menu and remove event listeners
 */

export const onHandleMenuFocus = (
  evt,
  {
    focusedItem,
    menuLength,
    startElementTag,
    endElementTag,
    onUpdateFocusedItem,
    onDestroy,
    categoryIsExpanded = false,
  },
) => {
  const START_ELEMENT = startElementTag
  const END_ELEMENT = endElementTag
  const FIRST_ITEM_INDEX = 0
  const LAST_ITEM_INDEX = menuLength - 1

  if (!categoryIsExpanded) {
    if (evt.keyCode === keyCodes.UP) {
      if (focusedItem !== null) {
        evt.preventDefault()

        if (focusedItem === FIRST_ITEM_INDEX) {
          return onUpdateFocusedItem(LAST_ITEM_INDEX)
        }

        if (focusedItem > FIRST_ITEM_INDEX) {
          return onUpdateFocusedItem(focusedItem - 1)
        }
      }
    }

    if (evt.keyCode === keyCodes.DOWN) {
      if (focusedItem === null && (evt.target.tagName === START_ELEMENT || evt.target.tagName === END_ELEMENT)) {
        evt.preventDefault()
        return onUpdateFocusedItem(FIRST_ITEM_INDEX)
      }

      if (focusedItem !== null) {
        if (focusedItem < LAST_ITEM_INDEX) {
          evt.preventDefault()
          return onUpdateFocusedItem(focusedItem + 1)
        }

        if (focusedItem === LAST_ITEM_INDEX) {
          evt.preventDefault()
          return onUpdateFocusedItem(FIRST_ITEM_INDEX)
        }
      }
    }
  }

  if (evt.keyCode === keyCodes.TAB) {
    if (focusedItem === null) {
      if (evt.shiftKey) {
        return evt.target.tagName === END_ELEMENT ? onDestroy() : false
      }

      if (evt.target.tagName === START_ELEMENT) {
        evt.preventDefault()
        return onUpdateFocusedItem(FIRST_ITEM_INDEX)
      }
    }

    if (focusedItem === FIRST_ITEM_INDEX) {
      if (evt.shiftKey) {
        return onUpdateFocusedItem(null)
      }

      evt.preventDefault()
      return onUpdateFocusedItem(FIRST_ITEM_INDEX + 1)
    }

    if (focusedItem > FIRST_ITEM_INDEX && focusedItem < LAST_ITEM_INDEX) {
      evt.preventDefault()
      const index = evt.shiftKey ? focusedItem - 1 : focusedItem + 1
      return onUpdateFocusedItem(index)
    }

    if (focusedItem === LAST_ITEM_INDEX) {
      if (evt.shiftKey) {
        evt.preventDefault()
        return onUpdateFocusedItem(LAST_ITEM_INDEX - 1)
      }

      onDestroy()
      return onUpdateFocusedItem(null)
    }
  }

  if (evt.keyCode === keyCodes.ESC) {
    evt.preventDefault()
    return onDestroy(evt)
  }

  return false
}

/**
 * Handles specific key events for the MegaMenu level two category
 * @param {object} event keydown event.
 * @param {object} config Object containing necessary menu states and callbacks.
 * @property {object} config.shopAllRef - React ref of L2 category 'shop all x' DOM node
 * @property {bool} config.categoryIsFocused - indicates whether L2 category is currently in focus
 * @property {object} config.categoryImageLink - DOM node of the last L2 category image link (if present)
 * @property {array} config.categoryTextLinks - List of DOM nodes of all L1, L2 & L3 links
 * @property {function} config.onToggleCategoryFocus - callback to update nav instance indicating if L2 category is focused
 * @property {function} config.onSaveCategoryLinks - callback to select the DOM nodes for the L2 category links
 * @property {function} config.onDestroyCategory - callback to close the category and refocus the L1 menu link
 */

export const onHandleMenuCategoryFocus = (
  evt,
  {
    shopAllRef,
    categoryIsFocused,
    categoryImageLink,
    categoryTextLinks,
    onToggleCategoryFocus,
    onSaveCategoryLinks,
    onDestroyCategory,
  },
) => {
  if (evt.keyCode === keyCodes.TAB) {
    if (!categoryIsFocused && !evt.shiftKey) {
      evt.preventDefault()
      onToggleCategoryFocus(true)
      onSaveCategoryLinks() /* cache query selector calls once only when menu is opened */
      return shopAllRef.current.focus()
    }

    const LAST_LINK = categoryTextLinks[categoryTextLinks.length - 1]

    if (categoryImageLink) {
      if (evt.shiftKey) {
        if (document.activeElement === shopAllRef.current) {
          evt.preventDefault()
          return categoryImageLink.focus()
        }
        return false
      }

      if (document.activeElement === categoryImageLink) {
        evt.preventDefault()
        return shopAllRef.current.focus()
      }
    }

    if (!categoryImageLink && categoryTextLinks) {
      if (evt.shiftKey) {
        if (document.activeElement === shopAllRef.current) {
          evt.preventDefault()
          return LAST_LINK.focus()
        }
        return false
      }

      if (document.activeElement === LAST_LINK) {
        evt.preventDefault()
        return shopAllRef.current.focus()
      }
    }

    return false
  }

  if (evt.keyCode === keyCodes.ESC || evt.keyCode === keyCodes.LEFT) {
    return onDestroyCategory()
  }

  return false
}
