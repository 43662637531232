import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider, subscribe } from 'react-contextual'
import ErrorBoundary from '@argos/error-boundary'
import { getSearchPath } from '@sainsburys-tech/boltui-utils'
import sanitiseSearch from '../../helpers/sanitiseSearchTerm'
import { searchUrlBuilder } from '../../helpers/analyticsUrlBuilder'
import config from '../../config/config'

import { SuggestionsPlaceholder } from '../../components/Placeholders'
import SearchStore from '../../stores/Search'
import { onHandleMenuFocus, keyCodes } from '../../helpers/menuKeyboardControls'

import styles from './Suggestions.scss'

export class Suggestions extends Component {
  static propTypes = {
    clearSuggestions: PropTypes.func,
    fetchSuggestions: PropTypes.func,
    focusedSuggestion: PropTypes.number,
    hasSearched: PropTypes.bool,
    minSearchCharacters: PropTypes.number,
    searchValue: PropTypes.string,
    showAutoSuggest: PropTypes.bool,
    suggestions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
    toggleAutoSuggest: PropTypes.func,
    updateChosenSuggestion: PropTypes.func,
  }

  static defaultProps = {
    searchValue: '',
    suggestions: [],
    showAutoSuggest: false,
    minSearchCharacters: 3,
    focusedSuggestion: null,
    fetchSuggestions: () => {},
    toggleAutoSuggest: () => {},
    updateChosenSuggestion: () => {},
    clearSuggestions: () => {},
    hasSearched: false,
  }

  constructor(props) {
    super(props)

    this.searchRef = React.createRef()
    this.suggestionRefs = []
    this.keyCodes = {
      UP: 38,
      DOWN: 40,
      TAB: 9,
      ESC: 27,
      SPACE: 32,
      ENTER: 13,
    }

    this.renderMenuItem = this.renderMenuItem.bind(this)
    this.handleDocumentClick = this.handleDocumentClick.bind(this)
    this.handleMenuKeydown = this.handleMenuKeydown.bind(this)
  }

  get tabIndex() {
    return this.props.showAutoSuggest ? 1 : -1
  }

  get hasSuggestions() {
    return !this.props.gettingSuggestions && this.props.suggestions && this.props.suggestions.length > 0
  }

  get screenReaderAnnouncement() {
    if (!this.props.hasSearched || this.props.gettingSuggestions) {
      return ''
    }

    if (this.hasSuggestions) {
      return `${this.props.suggestions.length} results available. ${config.autoComplete.screenReaderDescription}`
    }

    return 'No suggestions available'
  }

  // eslint-disable-next-line consistent-return
  componentWillReceiveProps(nextProps) {
    const {
      searchValue,
      showAutoSuggest,
      minSearchCharacters,
      hasSearched,
      clearSuggestions,
      fetchSuggestions,
    } = this.props

    /* Show menu if we already have suggestions and are re-focusing on the search input */
    if (nextProps.suggestions.length > 0 && !showAutoSuggest && nextProps.showAutoSuggest !== showAutoSuggest) {
      this.setupMenu()
    }

    /* Don't bother making another fetch if the searchValue hasn't changed */
    if (nextProps.searchValue.trim() === searchValue.trim()) {
      return false
    }

    /* Fetch the auto suggest results! */
    if (
      (nextProps.searchValue.trim().length >= minSearchCharacters && hasSearched) ||
      (nextProps.searchValue !== searchValue &&
        searchValue !== '' &&
        nextProps.searchValue.trim().length >= minSearchCharacters)
    ) {
      this.setupMenu()
      fetchSuggestions(nextProps.searchValue)
    } else {
      clearSuggestions()
      this.destroyMenu()
    }
  }

  componentWillUnmount() {
    this.removeListeners()
  }

  setupMenu() {
    this.props.toggleAutoSuggest(true)
    this.setupListeners()
  }

  destroyMenu() {
    this.props.toggleAutoSuggest(false)
    this.onUpdateFocusedItem(null)
    this.removeListeners()
  }

  setupListeners() {
    document.addEventListener('click', this.handleDocumentClick)
    document.addEventListener('keydown', this.handleMenuKeydown)
  }

  removeListeners() {
    document.removeEventListener('click', this.handleDocumentClick)
    document.removeEventListener('keydown', this.handleMenuKeydown)
  }

  handleDocumentClick(evt) {
    if (!this.searchRef.current.contains(evt.target) && this.props.showAutoSuggest) {
      this.destroyMenu()
    }
  }

  onUpdateFocusedItem(idx) {
    if (typeof idx !== 'object') {
      this.suggestionRefs[idx].focus()
    }

    this.props.updateFocusedSuggestion(idx)
  }

  handleMenuKeydown(evt) {
    const { suggestions, focusedSuggestion, searchValue } = this.props

    const onUpdateFocusedItem = this.onUpdateFocusedItem.bind(this)
    const onDestroy = this.destroyMenu.bind(this)

    if ((evt.keyCode === keyCodes.SPACE || evt.keyCode === keyCodes.ENTER) && focusedSuggestion !== null) {
      evt.preventDefault()
      return this.handleSuggestSearch(suggestions[focusedSuggestion], searchValue)
    }

    return onHandleMenuFocus(evt, {
      focusedItem: focusedSuggestion,
      menuLength: suggestions.length,
      startElementTag: 'BUTTON',
      endElementTag: 'INPUT',
      onUpdateFocusedItem,
      onDestroy,
    })
  }

  hrefBuilder(suggestion, searchValue, url) {
    const searchTerm = sanitiseSearch(searchValue).trim()
    const sanitisedSuggestion = sanitiseSearch(suggestion).trim()
    return `${url || getSearchPath(sanitisedSuggestion)}${searchUrlBuilder(searchTerm, 'suggest', sanitisedSuggestion)}`
  }

  handleSuggestSearch(suggestion, searchValue, url) {
    const sanitisedSuggestion = sanitiseSearch(suggestion).trim()
    this.props.updateChosenSuggestion(sanitisedSuggestion)
    this.destroyMenu()
    window.location.href = this.hrefBuilder(suggestion, searchValue, url)
  }

  renderMenuItem(suggestion, idx) {
    const { searchValue } = this.props
    const isFocused = this.props.focusedSuggestion === idx

    return (
      <li key={suggestion} aria-selected={isFocused} role='option' id={isFocused && 'selectedOption'}>
        <a
          aria-label={suggestion}
          href={this.hrefBuilder(suggestion, searchValue)}
          tabIndex={1}
          className={`${styles.item} ${isFocused ? styles.itemActive : ''}`}
          data-el='suggested-term'
          ref={(ref) => {
            this.suggestionRefs[idx] = ref
          }}
          onClick={() => this.handleSuggestSearch(suggestion, searchValue)}>
          {this.getHighlights(suggestion, searchValue)}
        </a>
      </li>
    )
  }

  getHighlights(suggestion, searchValue) {
    const term = searchValue.trim().replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&') // eslint-disable-line
    const re = new RegExp(`${term}`, 'g')
    const match = re.exec(suggestion.trim())
    const highlightedSuggestion = []

    if (match) {
      const splitDelimiter = new RegExp(`(${match[0]})`)
      suggestion.split(splitDelimiter).forEach((subString) => {
        if (subString === match[0]) {
          highlightedSuggestion.push(
            <span key={subString} style={{ fontWeight: 'bold' }} data-el='original-term'>
              {subString}
            </span>,
          )
        } else {
          highlightedSuggestion.push(subString)
        }
      })
    }
    return highlightedSuggestion.length !== 0 ? highlightedSuggestion : suggestion
  }

  render() {
    const { children: SearchComponent, showAutoSuggest, gettingSuggestions, suggestions } = this.props
    return (
      <div className={styles.container} ref={this.searchRef}>
        {React.cloneElement(SearchComponent, { closeSuggestions: () => this.destroyMenu() })}

        <span id='haas-ac' className='sr-only'>
          {config.autoComplete.screenReaderDescription}
        </span>

        <span aria-live='assertive' className='sr-only'>
          {this.screenReaderAnnouncement}
        </span>

        {showAutoSuggest && (
          <ol role='listbox' id='haas-ac-results' className={styles.suggestions}>
            <span className={styles.title}>Suggested Searches</span>

            {gettingSuggestions && <SuggestionsPlaceholder className={styles.placeholder} />}

            {this.hasSuggestions && suggestions.map(this.renderMenuItem)}
          </ol>
        )}
      </div>
    )
  }
}

const SubscribedComponent = subscribe([SearchStore], (search) => ({
  searchValue: search.searchTerm,
  overrideSearchTerm: search.overrideSearchTerm,
  showAutoSuggest: search.showAutoSuggest,
  gettingSuggestions: search.gettingSuggestions,
  suggestions: search.suggestions,
  focusedSuggestion: search.focusedSuggestion,
  hasSearched: search.hasSearched,
  clearSuggestions: search.clearSuggestions,
  updateSearchTerm: (searchValue) => search.updateSearchTerm(searchValue),
  updateFocusedSuggestion: (idx) => search.updateFocusedSuggestion(idx),
  updateChosenSuggestion: (suggestion) => search.updateChosenSuggestion(suggestion),
  fetchSuggestions: (searchValue) => {
    search.updateGettingSuggestions(true)
    search.fetchSuggestions(searchValue)
  },
  toggleAutoSuggest: (toggled) => search.toggleAutoSuggest(toggled),
}))(Suggestions)

const ProvidedComponent = (props) => (
  <ErrorBoundary>
    <Provider store={SearchStore}>
      <SubscribedComponent {...props} />
    </Provider>
  </ErrorBoundary>
)

export default ProvidedComponent
