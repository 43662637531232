import React from 'react'
import PropTypes from 'prop-types'
import { SearchIcon } from '@boltui/icons'

import { ClearSearch } from '../../components'
import styles from './SearchForm.scss'

const SearchForm = ({
  showAutoSuggest,
  chosenSuggestion,
  focusedSuggestion,
  searchValue,
  onClear,
  onChange,
  onSubmit,
  onFocus,
  onBlur,
}) => (
  <form className={styles.form} onSubmit={onSubmit} action='/search/' role='search' method='get' acceptCharset='utf-8'>
    <SearchIcon height='16px' width='16px' className={styles.searchIcon} fill='grey' aria-hidden={true} />

    <label className='sr-only' htmlFor='searchTerm'>
      Search
    </label>

    <input
      role='combobox'
      aria-expanded={showAutoSuggest}
      aria-controls='haas-ac-results'
      aria-autocomplete='both'
      aria-describedby='haas-ac'
      aria-owns='haas-ac-results'
      aria-activedescendant={focusedSuggestion !== null ? 'selectedOption' : ''}
      className={styles.input}
      type='search'
      name='searchTerm'
      autoComplete='off'
      autoCorrect='off'
      autoCapitalize='off'
      spellCheck='false'
      placeholder='Search products, brands or FAQs'
      id='searchTerm'
      value={chosenSuggestion || searchValue}
      data-test='search-input'
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
    />

    {searchValue && <ClearSearch onClick={onClear} />}

    <button className={styles.button} type='submit' data-test='search-button'>
      <span className='sr-only'>Search button</span>
      <span className={styles.buttonText}>Search</span>
      <SearchIcon height='20px' width='20px' fill='white' className={styles.buttonIcon} />
    </button>
  </form>
)

SearchForm.propTypes = {
  chosenSuggestion: PropTypes.string,
  focusedSuggestion: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  showAutoSuggest: PropTypes.bool,
}

SearchForm.defaultProps = {
  showAutoSuggest: false,
  chosenSuggestion: null,
  focusedSuggestion: null,
  searchValue: null,
  onFocus: () => {},
  onBlur: () => {},
}

export default SearchForm
