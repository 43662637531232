import { createStore } from 'react-contextual'
import { isMobile, isShortViewport } from '../helpers/viewPortHelper'

export default createStore({
  focusedItem: null,
  flyoutFocusedItem: null,
  menuToggled: false,
  keyboardActivated: false,
  activeMenu: 0,
  flyoutActive: false,
  previousMenu: 0,
  activeCategory: {
    index: null,
    title: null,
    link: null,
  },
  activeFlyoutCategory: {
    title: null,
    link: null,
  },
  activeDrillDownCategory: {
    title: null,
    link: null,
    links: null,
  },
  activeDrillDownLevelTwoCategory: {
    title: null,
    links: null,
  },
  onUpdateFocusedItem: (focusedItem) => ({ focusedItem }),
  onUpdateFlyoutFocusedItem: (flyoutFocusedItem) => ({ flyoutFocusedItem }),
  onUpdateActiveMenu: (activeMenu) => ({ activeMenu }),
  onUpdateFlyoutMenu: (flyoutActive) => ({ flyoutActive }),
  onUpdatePreviousMenu: (previousMenu) => ({ previousMenu }),
  onUpdateActiveCategory: (activeCategory) => ({ activeCategory }),
  onUpdateActiveFlyoutCategory: (activeFlyoutCategory) => ({ activeFlyoutCategory }),
  onUpdateActiveDrillDownCategory: (activeDrillDownCategory) => ({ activeDrillDownCategory }),
  onUpdateActiveDrillDownLevelTwoCategory: (activeDrillDownLevelTwoCategory) => ({ activeDrillDownLevelTwoCategory }),
  onSetKeyboardActivated: (keyboardActivated) => ({ keyboardActivated }),
  onToggleMenu: (evt, menuToggled, onUpdateActiveMenu) => {
    if (evt) evt.preventDefault()
    if (menuToggled) {
      // On mobile, open at level 0 (e.g. for Shop at Argos and Need Help?). On tablet/desktop, open at level 1 for categories
      (isMobile(global) && onUpdateActiveMenu(0)) || onUpdateActiveMenu(1)
      // only disable scroll on the body/overflow if the screen is greater than a shortViewport to fix hidden categories issue
      document.body.style.overflow = (!isMobile(global) && isShortViewport(global)) ? '' : 'hidden'
    } else if (!menuToggled) {
      document.body.style.overflow = ''
      onUpdateActiveMenu(0) // Reset menu level on close
    }
    return {
      menuToggled,
      previousMenu: 0, // Reset previous menu level stored
    }
  },
})
