import React from 'react'
import PropTypes from 'prop-types'

import styles from './MegaMenuLevels.scss'

import MegaMenuImage from '../MegaMenuImage/MegaMenuImage'
import MegaMenuLink from '../MegaMenuLink/MegaMenuLink'

const MenuLevelThree = (link, idx) => {
  if (link.image) {
    return <MegaMenuImage key={`${link.title}-${idx}`} href={link.link} alt={link.title} src={link.image} />
  }

  return <MegaMenuLink key={`${link.title}-${idx}`} href={link.link} text={link.title} role={'menuitem'} />
}

const MenuLevelTwo = (section, idx) => (
  <ul key={`${section.title}-${idx}`} className={styles.linkBlock}>
    {section.title !== 'IMAGE_HEADER' ? <span className={styles.linkBlockItem}>{section.title}</span> : null}
    <ul>{section.links.map(MenuLevelThree)}</ul>
  </ul>
)

const MenuColumn = (column, idx) => (
  <li key={`col-${idx}`} className={'mega-menu-column'}>
    {column.map(MenuLevelTwo)}
  </li>
)

export const MegaMenuLevels = ({ category = { columns: [] } }) =>
  category.columns.length > 0 ? category.columns.map(MenuColumn) : null

MegaMenuLevels.propTypes = {
  activeCategory: PropTypes.shape({
    index: PropTypes.number,
    text: PropTypes.string,
    link: PropTypes.string,
  }),
  taxonomy: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
      columns: PropTypes.arrayOf(
        PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            links: PropTypes.arrayOf(
              PropTypes.shape({
                link: PropTypes.string,
                title: PropTypes.string,
              }),
            ),
          }),
        ),
      ),
    }),
  ),
}

MegaMenuLevels.defaultProps = {
  taxonomy: [],
  activeCategory: {
    index: null,
  },
}

export default MegaMenuLevels
