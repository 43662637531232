import React from 'react'
import PropTypes from 'prop-types'

const HSTSPixel = ({ brand = 'argos' }) => (
  <img
    src={`https://${brand}.co.uk/hsts.gif`}
    data-test='hsts-pixel'
    alt='HSTS Pixel'
    role='presentation'
    width='1'
    height='1'
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      display: 'none',
      visibility: 'hidden',
    }}
  />
)

HSTSPixel.propTypes = {
  brand: PropTypes.string,
}

HSTSPixel.defaultProps = {
  brand: 'argos',
}

export default HSTSPixel
