import React from 'react'
import PropTypes from 'prop-types'
import { ChevronIcon } from '@boltui/icons'
import { MegaMenuCategory } from '../../components'
import { megaButtonsUrlBuilder } from '../../helpers/analyticsUrlBuilder'

import MegaMenuSlideTransition from '../MegaMenuSlideTransition/MegaMenuSlideTransition'
import styles from '../MegaMenuCategories/MegaMenuCategories.scss'
import { isMobileOrTablet } from '../../helpers/viewPortHelper'

const FlyoutMenu = ({
  activeFlyoutCategory,
  isActive,
  isSlidingLeft,
  links,
  menuLevel,
  onBackButtonClick,
  onMouseEnter,
  onMouseLeave,
  onSetRef,
  animate,
}) => {
  const backButtonText = 'Back to Categories'

  return (
    <li className={'mega-menu-column mega-menu-column--flyout'} data-active={isActive} data-menulevel={menuLevel}>
      <MegaMenuSlideTransition
        isSlidingLeft={isSlidingLeft}
        show={isActive}
        enter={animate}
        exit={animate}
        unmountOnExit={isMobileOrTablet(global)}>
        <ul className={styles.menu} data-el={`level-${menuLevel}`}>
          {/* Mobile Back Button */}
          <li className={`${styles.category} ${styles.menuItem} ${styles.back}`}>
            <a
              href={'/'}
              tabIndex={0}
              className={styles.link}
              data-test={'megamenu-back-to-categories'}
              onClick={(evt) => onBackButtonClick(menuLevel - 1, evt)}>
              <ChevronIcon dir='left' className={styles.leftArrow} />
              {backButtonText}
            </a>
          </li>
          <ul className={styles.scrollableArea}>
            {links &&
              links.map((button, idx) => {
                const menuLink = { title: button.text, link: megaButtonsUrlBuilder(button.link, button.text) }
                return (
                  <MegaMenuCategory
                    isExpanded={activeFlyoutCategory.title === button.text}
                    category={menuLink}
                    key={`${menuLink.title}-${idx}`}
                    href={menuLink.link}
                    idx={idx}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onSetRef={onSetRef}
                    isFlyout={true}
                  />
                )
              })}
          </ul>
        </ul>
      </MegaMenuSlideTransition>
    </li>
  )
}

FlyoutMenu.propTypes = {
  activeFlyoutCategory: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
  }),
  animate: PropTypes.bool,
  isActive: PropTypes.bool,
  isSlidingLeft: PropTypes.bool,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  menuLevel: PropTypes.number,
  onBackButtonClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onSetRef: PropTypes.func,
}

FlyoutMenu.defaultProps = {
  activeFlyoutCategory: {
    text: null,
    link: null,
  },
  animate: true,
  isActive: false,
  isSlidingLeft: false,
  links: [],
  menuLevel: null,
  onBackButtonClick: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  onSetRef: () => {},
}

export default FlyoutMenu
