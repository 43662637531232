/*
  Cookies dropped after calling cis/refresh
  If both of these cookies exist, then header should NOT call the cis refresh endpoint
*/
const cisIdCookieName = 'cisId'
const cisDelayCookieName = 'argos_cis_delay_refresh'

export const getCisIdCookieValue = () => {
  const cookies = global.document.cookie
  const cisIdCookie = cookies.split(';').filter((cookie) => cookie.trim().startsWith(`${cisIdCookieName}=`))
  return cisIdCookie && cisIdCookie.length ? cisIdCookie[0].split('=')[1] : ''
}

// Check whether to call the refresh endpoint
export const shouldRefreshSession = () => {
  const cookies = global.document.cookie
  const cisIdExists = cookies.split(';').filter((item) => item.trim().startsWith(`${cisIdCookieName}=`)).length
  const cisDelayExists = cookies.split(';').filter((item) => item.trim().startsWith(`${cisDelayCookieName}=`)).length
  return !cisIdExists || !cisDelayExists
}

// Check whether to call the refresh endpoint with syncLocalisation header
export const shouldSyncLocalisationSession = () => {
  const cookies = global.document.cookie
  const cisIdExists = cookies.split(';').filter((item) => item.trim().startsWith(`${cisIdCookieName}=`)).length
  const isAOVariant = cookies.split(';').some((item) => item.includes('Header_Test_Group_1=2'))
  return cisIdExists && isAOVariant
}
