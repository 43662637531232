import { createStore } from 'react-contextual'

import SuggestionsService from '../services/Suggestions'

export default createStore({
  searchTerm: '',
  searchFocused: false,
  chosenSuggestion: '',
  showAutoSuggest: false,
  gettingSuggestions: true,
  suggestions: [],
  focusedSuggestion: null,
  hasSearched: false,
  updateSearchTerm: (searchTerm) => ({ searchTerm }),
  updateChosenSuggestion: (chosenSuggestion) => ({ chosenSuggestion }),
  updateGettingSuggestions: (gettingSuggestions) => ({ gettingSuggestions }),
  updateFocusedSuggestion: (focusedSuggestion) => ({ focusedSuggestion }),
  clearSuggestions: () => ({ suggestions: [] }),
  updateHasSearched: (hasSearched) => ({ hasSearched }),
  toggleAutoSuggest: (showAutoSuggest) => ({ showAutoSuggest }),
  fetchSuggestions: (searchTerm) =>
    SuggestionsService(searchTerm).then((suggestions) => ({
      suggestions,
      gettingSuggestions: false,
      chosenSuggestion: '',
    })),
  onSearchFocus: (props) => ({
    searchFocused: true,
    showAutoSuggest: (props.suggestions.length > 0 && !props.showAutoSuggest) || props.showAutoSuggest,
  }),
  onSearchBlur: () => ({ searchFocused: false }),
})
