import canUseDom from '@argos/utils/esnext/canUseDom'

export default function getSiteSection() {
  if (
    canUseDom() &&
    global.digitalData &&
    global.digitalData.page &&
    global.digitalData.page.pageInfo &&
    global.digitalData.page.pageInfo.siteSection
  ) {
    const siteSection = global.digitalData.page.pageInfo.siteSection.substring(3)

    if (siteSection === 'wishlist') {
      return 'wishlist:'
    }
    return siteSection
  }
  return ''
}
