import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider, subscribe } from 'react-contextual'
import ErrorBoundary from '@argos/error-boundary'
import { AccountIcon, TrolleyIcon, MenuIcon, WishlistIcon } from '@boltui/icons'

import UserLink from '../../components/UserLink/UserLink'
import { NavigationStore, UserStore, WishlistStore } from '../../stores'
import {
  shouldRefreshSession,
  getCisIdCookieValue,
  shouldSyncLocalisationSession,
} from '../../helpers/cisSessionHelper'
import getSiteSection from '../../helpers/getSiteSection'

import styles from './User.scss'

export class User extends Component {
  static propTypes = {
    blackFriday: PropTypes.bool,
    onCisCookieCreated: PropTypes.func,
    onFetchUser: PropTypes.func.isRequired,
    onSetSession: PropTypes.func,
    onSetSyncLocalisation: PropTypes.func,
    onToggleMenu: PropTypes.func.isRequired,
    onUserInfoLoad: PropTypes.func,
    overrideTrolleyCount: PropTypes.number,
    overrideWishlistCount: PropTypes.oneOfType([PropTypes.number, PropTypes.element]),
    trolleyCount: PropTypes.number,
    trolleyCountChange: PropTypes.number,
    wishlistCount: PropTypes.number,
  }

  static defaultProps = {
    wishlistCount: 0,
    trolleyCount: 0,
    trolleyCountChange: 0,
    overrideTrolleyCount: null,
    overrideWishlistCount: null,
    onUserInfoLoad: () => ({}),
    onCisCookieCreated: () => false,
    blackFriday: false,
  }

  componentDidMount() {
    const { onFetchUser } = this.props
    onFetchUser(this.props.onUserInfoLoad)
    this.checkSessionIdCreated()
  }

  checkSessionIdCreated = async () => {
    const { onSetSession, onSetSyncLocalisation } = this.props
    if (shouldRefreshSession()) {
      await onSetSession()
    }

    // Temporary solution to rollback AO A/B test by calling refresh again with syncLocalisation header
    if (shouldSyncLocalisationSession()) {
      await onSetSyncLocalisation()
    }

    const cisIdValue = getCisIdCookieValue()
    this.props.onCisCookieCreated(cisIdValue)
  }

  get wishlistCount() {
    const { wishlistCount: count, overrideWishlistCount: override } = this.props
    return override || override === 0 ? override : count
  }

  get trolleyCount() {
    const { trolleyCount: count, overrideTrolleyCount: override, trolleyCountChange } = this.props
    return override || override === 0 ? override : count + trolleyCountChange
  }

  render() {
    const { onToggleMenu, blackFriday } = this.props

    return (
      <div className={styles.userContainer}>
        {blackFriday && (
          <UserLink
            name={'Summer\nSale'}
            className={`${styles.user} ${styles.blackFridayIcon}`}
            href={`/events/sale?tag=ar:topnav:mobile:sale`}
          />
        )}
        <UserLink
          name={'Shop'}
          icon={MenuIcon}
          onClick={onToggleMenu}
          className={`${styles.user} ${styles.shopIcon}`}
          href={`/?clickOrigin=header:${getSiteSection()}shop`}
        />
        <UserLink
          name={'Account'}
          icon={AccountIcon}
          className={styles.user}
          href={`/account/login?clickOrigin=header:${getSiteSection()}account`}
        />
        <UserLink
          name={'Wishlist'}
          icon={WishlistIcon}
          count={this.wishlistCount}
          className={`${styles.user} ${blackFriday ? styles.wishlistBFIcon : ``}`}
          href={`/wishlist?clickOrigin=header:${getSiteSection()}wishlist`}
        />
        <UserLink
          name={'Trolley'}
          data-test='header-trolley-count'
          icon={TrolleyIcon}
          count={this.trolleyCount}
          className={styles.user}
          href={`/basket?clickOrigin=header:${getSiteSection()}trolley`}
        />
      </div>
    )
  }
}

const SubscribedComponent = subscribe([UserStore, WishlistStore, NavigationStore], (user, wishlist, navigation) => {
  return {
    wishlistCount: wishlist.wishlistCount,
    activeMenu: navigation.activeMenu,
    trolleyCount: user.trolleyCount,
    onFetchUser: (callback) => user.fetchUser(callback),
    onSetSession: () => user.setSession(),
    onSetSyncLocalisation: () => user.setSyncLocalisation(),
    onToggleMenu: (evt) => {
      evt.stopPropagation()
      navigation.onToggleMenu(evt, !navigation.menuToggled, navigation.onUpdateActiveMenu)
    },
  }
})(User)

const ProvidedComponent = (props) => (
  <ErrorBoundary>
    <Provider store={WishlistStore}>
      <SubscribedComponent {...props} />
    </Provider>
  </ErrorBoundary>
)

export default ProvidedComponent
