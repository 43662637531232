import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { subscribe } from 'react-contextual'
import ErrorBoundary from '@argos/error-boundary'

import MegaMenuComponent from '../../components/MegaMenu/MegaMenu'
import { NavigationStore } from '../../stores'

import styles from './MegaMenu.scss'

const vhCssProperty = '--vh'

export class MegaMenu extends React.Component {
  static propTypes = {
    activeCategory: PropTypes.shape({
      index: PropTypes.number,
      title: PropTypes.string,
      link: PropTypes.string,
    }),
    focusedItem: PropTypes.number,
    menuToggled: PropTypes.bool,
    onSetRef: PropTypes.func,
    onToggleCategoryFocus: PropTypes.func,
    onUpdateActiveCategory: PropTypes.func,
    onUpdateActiveMenu: PropTypes.func,
    shopAllRef: PropTypes.shape({
      current: PropTypes.string,
    }),
    taxonomy: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.string,
        title: PropTypes.string,
        columns: PropTypes.arrayOf(
          PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string,
              links: PropTypes.arrayOf(
                PropTypes.shape({
                  link: PropTypes.string,
                  title: PropTypes.string,
                }),
              ),
            }),
          ),
        ),
      }),
    ),
  }

  static defaultProps = {
    taxonomy: [],
    activeCategory: {
      index: null,
      title: null,
      link: null,
    },
    menuToggled: false,
    focusedItem: null,
    shopAllRef: null,
    onSetRef: () => {},
    onUpdateActiveMenu: () => {},
    onUpdateActiveCategory: () => {},
    onToggleCategoryFocus: () => {},
  }

  constructor(props) {
    super(props)
    this.MegaMenuComponent = null
    this.megaMenuRef = React.createRef()
  }

  // Some mobile browsers don't like 'height: calc(vh);' (in .scrollableArea class)
  // so recalculate vh using innerHeight and set it as a CSS variable
  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  setViewHeightProperty = () => {
    const megaMenuElement = this.megaMenuRef.current
    if (megaMenuElement && megaMenuElement.style && window && window.innerHeight) {
      const vh = window.innerHeight * 0.01
      megaMenuElement.style.setProperty(vhCssProperty, `${vh}px`)
    }
  }

  componentDidMount() {
    this.setViewHeightProperty()
    window.addEventListener('resize', this.setViewHeightProperty)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setViewHeightProperty)
  }

  buildMenu() {
    return (
      <MegaMenuComponent
        taxonomy={this.props.taxonomy}
        activeCategory={this.props.activeCategory}
        focusedItem={this.props.focusedItem}
        isFallbackMenu={false}
        shopAllRef={this.props.shopAllRef}
        onSetRef={this.props.onSetRef}
        onUpdateActiveMenu={this.props.onUpdateActiveMenu}
        onUpdateActiveCategory={this.props.onUpdateActiveCategory}
        onToggleCategoryFocus={this.props.onToggleCategoryFocus}
      />
    )
  }

  render() {
    const MenuComponent = this.buildMenu()
    const { title, index, link } = this.props.activeCategory
    const submenuActive = title
    const submenuActiveNoContent = !title && (index && link)
    const boxShadow = !submenuActive || submenuActiveNoContent === this.props.taxonomy.length ? true : null

    return (
      <Fragment>
        <ul
          ref={this.megaMenuRef}
          id='megaMenu'
          aria-labelledby='ShopLink'
          className={styles.megaMenu}
          data-submenu-active={submenuActive}
          data-submenu-active-no-content={submenuActiveNoContent}
          data-box-shadow={boxShadow}
          role='menubar'>
          {MenuComponent}
        </ul>
      </Fragment>
    )
  }
}

const SubscribedComponent = subscribe([NavigationStore], (navigation) => ({
  menuToggled: navigation.menuToggled,
  focusedItem: navigation.focusedItem,
  activeCategory: navigation.activeCategory,
}))(subscribe()(MegaMenu))

const ProvidedComponent = (props) => (
  <ErrorBoundary>
    <SubscribedComponent {...props} />
  </ErrorBoundary>
)

export default ProvidedComponent
