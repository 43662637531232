import request from 'superagent'

// eslint-disable-next-line import/no-self-import
import * as Wishlist from './Wishlist'
import endpoints from '../config/endpoints'

/**
 * Get Wishlist count from browser localStorage
 * @return {Promise} resolves to an object containing the count e.g. { wishlistCount: 3 }
 */
export function getWishlistLocal() {
  return new Promise((resolve, reject) => {
    try {
      const localWishlist = localStorage.getItem('wishlist')

      if (!localWishlist) {
        return resolve(0)
      }

      const parsedResponse = JSON.parse(localWishlist)

      return resolve({ wishlistCount: parsedResponse.wishlists[0].items.length })
    } catch (e) {
      return reject(new Error(e))
    }
  })
}

/**
 * Get the Wishlist count from the GraphQL Wishlist Service
 * @return {Promise} resolves to an object containing the count e.g. { wishlistCount: 3 }
 */
export function getWishlistAccount() {
  const query = '{ wishlists { items { partNumber } } }'

  return new Promise((resolve, reject) =>
    request
      .post(endpoints.wishlist)
      .type('application/json')
      .send({ query })
      .then((result) => {
        if (!result.body) {
          return reject(new Error(`Invalid response: ${result.body}`))
        }

        if (result.body.errors) {
          return reject(new Error(result.body.errors[0].message))
        }

        const wishlist = result.body.data.wishlists[0] /* multiple wishlists unsupported */

        return resolve({ wishlistCount: wishlist.items.length })
      })
      .catch((error) => reject(new Error(error))),
  )
}

export default (isLoggedIn) => {
  if (isLoggedIn) {
    return Wishlist.getWishlistAccount()
  }

  return Wishlist.getWishlistLocal()
}
