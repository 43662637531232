import React from 'react'
import { subscribe } from 'react-contextual'
import PropTypes from 'prop-types'
import ErrorBoundary from '@argos/error-boundary'

import { NavigationStore } from '../../stores'
import styles from './BackDrop.scss'

export const BackDrop = ({ submenuActive }) => submenuActive.title && <div className={styles.backdrop} />

BackDrop.defaultProps = {
  submenuActive: {
    index: null,
    title: null,
    link: null,
  },
}

BackDrop.propTypes = {
  submenuActive: PropTypes.shape({
    index: PropTypes.number,
    link: PropTypes.string,
    title: PropTypes.string,
  }),
}

const SubscribedComponent = subscribe([NavigationStore], (navigation) => ({
  submenuActive: navigation.activeCategory,
}))(subscribe()(BackDrop))

const ProvidedComponent = (props) => (
  <ErrorBoundary>
    <SubscribedComponent {...props} />
  </ErrorBoundary>
)

export default ProvidedComponent
