import request from 'superagent'

import endpoints from '../config/endpoints'

export default (searchTerm) =>
  new Promise((resolve, reject) =>
    request
      .get(`${endpoints.suggestions}?term=${searchTerm}&fuzziness=true&size=5`)
      .then((result) => {
        if (!result.body) {
          return reject(new Error(`Invalid response: ${result.body}`))
        }

        return resolve(result.body.autoSuggest.keywords.map((keyword) => keyword.value))
      })
      .catch((error) => reject(new Error(error))),
  )
